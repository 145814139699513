import React, { useEffect } from "react";
// import Header from '../components/Header';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import { graphql } from "gatsby";
import "../styles/styles.scss";
import { addEventListner, onClientEntry } from "../utils/Event";
import { ThemeProvider } from "@emotion/react";
import theme from "../components/Theme";
// import Use from "../components/Use";
import ThirdCard from "../components/ThirdCard";
import FifthCards from "../components/FifthCards";
import SixthCard from "../components/SixthCard";
import FourthCard from "../components/FourthCard";
import heroData from "../data/hero_data";
import third_card from "../data/third_data";
import sheduleData from "../data/shedule_data";
import HeroImage from "../images/Hero-image1.png";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import SoftDevICon from '../images/soft-dev-icon.svg'
// import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Toast from "../components/Toast";
import Footer2 from '../components/Footer2'
const Index: React.FC = () => {
  useEffect(() => addEventListner());
  useEffect(() => onClientEntry());

  heroData.heroData1.image = HeroImage;
  third_card.feature_page_1.list.map((ele) => {
    switch (ele.name) {
      case "Mitigate Risks":
        ele.iconStyle.icon = (
          <NotificationsActiveOutlinedIcon
            sx={{
              fontSize: ele.iconStyle.iconSize,
              color: `${ele.iconStyle.iconColor}`,
            }}
          />
        );

        break;
      case "Custom Build Features":
        ele.iconStyle.icon = (
          SoftDevICon
        );

        break;
      case "Client Reporting":
        ele.iconStyle.icon = (
          <TaskOutlinedIcon
            sx={{
              fontSize: ele.iconStyle.iconSize,
              color: `${ele.iconStyle.iconColor}`,
            }}
          />
        );

        break;
      default:
        ele.iconStyle.icon = {};
    }
  });

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Toast />
        <Navbar />
        <Hero heroData={heroData.heroData1} />
        {/* <Use /> */}
        <ThirdCard thirdCardData={third_card.feature_page_1} />
        <FourthCard />
        <FifthCards sheduleData={sheduleData.banner1} />
        <SixthCard />
        <Footer />
        <Footer2/>
        {/* <Achievements /> */}
        {/* <NewsSection data={data}/> */}
        {/* <Footer />  */}
      </ThemeProvider>
    </React.Fragment>
  );
};

// export const pageQuery = graphql`
//   query myQuery {
//     meeting: file(name: { eq: "sixth_img" }) {
//       id
//       childImageSharp {
//         gatsbyImageData
//       }
//     }
//     helmet: file(name: { eq: "dextop-image-mb" }) {
//       id
//       childImageSharp {
//         gatsbyImageData
//       }
//     }
//   }
// `;

export default Index;
